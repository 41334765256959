<template>
  <div>
    <div class="grid-x grid-margin-x">
      <div class="cell medium-6">
        <card-item
          :title="$t('interface.button_primary')"
          icon="icon-interface-font-color"
        >
          <ab-color-picker
            :value.sync="settings.button_primary_bg_color"
            :label="$t('interface.background_color')"
            type="large"
          ></ab-color-picker>

          <ab-color-picker
            :value.sync="settings.button_primary_text_color"
            :label="$t('interface.text_color')"
            type="large"
          ></ab-color-picker>
          <div class="spacer--20"></div>
          <div class="grid-x">
            <a href="" class="button--primary">{{
                $t('interface.button_primary')
              }}</a
            ><a href="" class="button--primary-hollow">{{
              $t('interface.button_primary_hollow')
            }}</a>
          </div>
        </card-item>
      </div>
      <div class="cell medium-6">
        <card-item
          :title="$t('interface.button_secondary')"
          icon="icon-interface-font-color"
        >
          <ab-color-picker
            :value.sync="settings.button_secondary_line_color"
            :label="$t('interface.line_color')"
            type="large"
          ></ab-color-picker>

          <ab-color-picker
            :value.sync="settings.button_secondary_text_color"
            :label="$t('interface.text_color')"
            type="large"
          ></ab-color-picker>
          <div class="spacer--20"></div>
          <div class="grid-x">
            <a href="" class="button--secondary">{{
                $t('interface.button_secondary')
              }}</a
            ><a href="" class="button--secondary-hollow">{{
              $t('interface.button_secondary_hollow')
            }}</a>
          </div>
        </card-item>
      </div>
    </div>
    <div class="spacer--20"></div>
    <div class="grid-x grid-margin-x">
      <div class="cell medium-6">
        <card-item
          :title="$t('interface.text_links')"
          icon="icon-interface-font-color"
        >
          <ab-color-picker
            v-if="settings.button_text_link_color"
            :value.sync="settings.button_text_link_color"
            :label="$t('interface.color')"
            type="large"
          ></ab-color-picker>
        </card-item>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InterfaceSettingsButtons',
  props: ['content'],
  computed: {
    settings: {
      get () {
        return this.content
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  + a {
    margin-left: 20px;
  }
}
</style>
